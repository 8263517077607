import { push } from 'redux-first-history';
import moment from 'moment-timezone';
import Parser from 'html-react-parser';
import axios from 'axios';
import { NETWORK_CONNECTION_STATUS } from '../routes/actions';
import { BRANDS, userProfileInfoMeta } from './constants';
import store from '../store';
import findNextRoute, { applicationFlow, getRouteConfig, getProgressBarSteps } from './findNextRoute';
import { isEmpty } from './validations';
import { addDataLayerEventInfo, trackHistoryLocationChange } from './analytics-utils';
import { getAppData } from './store-utils';
import ableSpriteSheet from '../assets/img/svg/able-sprites.svg';

// NEVER IMPORT AXIOS BY ITESELF. ONE OFF SCENARIO PA-154

export const redirect = (url) => {
    window.location = url;
};

const { PPA_CREDIT_CARD_ENABLED = false, PPA_PAYPAL_ENABLED = false, PPA_VISA_ENABLED = false, PPA_BILLPAY_REDIRECT_URL = '', PPA_VISA_ENV = 'sandbox' } = window.appConfig || {};

export const addressEndpoint = () => {
    const { addressServiceBasePath = '' } = window.appConfig || {};
    return `${addressServiceBasePath}/addressautocomplete/search?query=`;
};

export const verifyAddressEndpoint = () => {
    const { addressServiceBasePath = '' } = window.appConfig || {};
    return `${addressServiceBasePath}/validation/simplifiedstructure`;
};

export const checkExistingTdiEndpoint = () => {
    const { tdiConfigurations: { checkTdibasePath = 'https://localhost:3001' } = {} } = window.appConfig || {};
    return checkTdibasePath;
};

export const registerTdiEndpoint = () => {
    const { tdiConfigurations: { registerTdibasePath = 'https://localhost:3001' } = {} } = window.appConfig || {};
    return registerTdibasePath;
};

export const getBillpayRedirectURL = () => {
    return window.location.origin + PPA_BILLPAY_REDIRECT_URL;
};

export const getVisaCheckoutConfig = () => {
    const buttonImage = PPA_VISA_ENV === 'sandbox' ? 'https://sandbox.secure.checkout.visa.com/wallet-services-web/xo/button.png' : 'https://secure.checkout.visa.com/wallet-services-web/xo/button.png';
    return { buttonImage };
};

export const route = (url) => {
    const { search = '' } = window.location || {};
    store.dispatch(push(`${url}${!isEmpty(search) ? search : ''}`));
    window.scrollTo(0, 0);
};

export const getCurrentApplicationPath = () => {
    const state = store.getState();
    const { pathname: currentLocation } = state.router.location;
    // const currentRouteName = !isEmpty(REACT_BASE_PATH) ? currentLocation.split(REACT_BASE_PATH).pop() : currentLocation;
    const currentRouteName = currentLocation;
    return currentRouteName;
};

export function nextPage(updateStepperCustomData = null, isLastStepCompleted = false, updatePath = '') {
    const {
        uiState: { loggedIn },
        customer: { found: customerFound }
    } = getAppData();
    const authenticatedUser = loggedIn && customerFound;
    const { totalSteps } = getProgressBarSteps();
    const currentRouteName = getCurrentApplicationPath();
    const routeData = isEmpty(updatePath) ? findNextRoute(currentRouteName) : findNextRoute(updatePath, true);
    const { nextRoute = false } = routeData;

    if (nextRoute) {
        const currentStep = authenticatedUser ? nextRoute.progressBar.authenticatedStep : nextRoute.progressBar.unAuthenticatedStep;
        if (updateStepperCustomData) {
            updateStepperCustomData({
                totalSteps,
                currentStep,
                isLastStepCompleted
            });
        }
    }
    route(nextRoute.path);
    return false;
}

export function focusOnHeading() {
    setTimeout(() => {
        const heading = document.querySelector('h1');
        if (heading) {
            heading.focus();
        }
    }, 1000);
}

export function focusOnElement(elem, delay = 1000, immediate = false) {
    const focusEl = () => {
        const element = document.querySelector(elem);
        if (element) {
            element.focus();
        }
    };
    if (immediate) {
        focusEl();
    } else {
        setTimeout(focusEl, delay);
    }
}

export function focusOnProgress() {
    setTimeout(() => {
        const progress = document.querySelector('div.progress-info');
        if (progress) {
            progress.focus();
        }
    }, 1000);
}

export function focusOnModal(modalRef, delay = 1000) {
    setTimeout(() => {
        if (modalRef && modalRef.current && modalRef.current.node) {
            const [modalHeading] = modalRef.current.node.getElementsByClassName('modal-heading') || [];
            if (modalHeading && modalHeading.focus) modalHeading.focus();
            const [modalOverlay] = modalRef.current.node.getElementsByClassName('ReactModal__Overlay') || [];
            if (modalOverlay && modalOverlay.removeAttribute) modalOverlay.removeAttribute('aria-modal');
        }
    }, delay);
}

export function resumeFlow(lastRoutePath, isAemLogin = false) {
    let routeProcessed = false;
    if (isAemLogin) {
        const routeConfig = getRouteConfig(lastRoutePath);
        if (routeConfig.render()) {
            routeProcessed = true;
            route(lastRoutePath);
        }
    }
    const currentRouteName = lastRoutePath;
    const { nextRoute = false } = findNextRoute(currentRouteName);

    if (nextRoute && !routeProcessed) {
        route(nextRoute.path);
    }

    return false;
}

export const getCurrentRouteConfig = () => {
    const currentRouteName = getCurrentApplicationPath();
    if (isEmpty(currentRouteName)) {
        return {};
    }
    return getRouteConfig(currentRouteName);
};
/**
 * Checks wether the debugger should be invoked
 * when the application is rendered
 *
 */
export const isDebuggingMode = () => {
    const { showReactDebugger = false } = window.appConfig || {};
    return showReactDebugger;
};

const getShoppingCartId = () => {
    const state = store.getState();
    const { app: { appData } = {} } = state;
    const { shoppingCart: { shoppingCartId = '' } = {} } = appData;
    return { shoppingCartId };
};

/**
 * Returns the current state of Identity form of customer.
 *
 * @returns {any} customerIdentityData
 */
const getIdentityFormData = () => {
    const state = store.getState();
    const { app: { appData } = {}, form: formData = {} } = state;
    const {
        uiState: { selectedIdentityType }
    } = appData;
    const { drivingLicenseForm = {}, australianPassportForm = {}, australianVisaForm = {}, medicareForm = {} } = formData;

    switch (selectedIdentityType) {
        case 'AUS_DRIVERS_LICENSE': {
            const { values: { firstName, middleName, familyName: lastName, dobDay, dobMonth, dobYear, stateOfIssue, licenseNumber, title } = {} } = drivingLicenseForm;
            return { firstName, middleName, lastName, dobDay, dobMonth, dobYear, stateOfIssue, licenseNumber, title };
        }
        case 'AUS_PASSPORT': {
            const { values: { givenName: firstName, middleName, familyName: lastName, dobDay, dobMonth, dobYear, title } = {} } = australianPassportForm;
            return { firstName, middleName, lastName, dobDay, dobMonth, dobYear, title };
        }
        case 'AUS_VISA': {
            const { values: { givenName: firstName, middleName, familyName: lastName, dobDay, dobMonth, dobYear, title } = {} } = australianVisaForm;
            return { firstName, middleName, lastName, dobDay, dobMonth, dobYear, title };
        }
        case 'MEDICARE_CARD': {
            const { values: { firstName, middleName, familyName: lastName, dobDay, dobMonth, dobYear, title } = {} } = medicareForm;
            return { firstName, middleName, lastName, dobDay, dobMonth, dobYear, title };
        }
        default:
            return {};
    }
};

/**
 * Returns current customer state.
 * This will return all user data avaiable
 * It will also take into considration of what form data takes precedence
 * as per business logic.
 * Eg. First name in identity details form should take priority over customer details form
 *
 * @returns {any} currentCustomerState
 */
export const getCustomerState = () => {
    const state = store.getState();
    const { app: { hasErrorOccurred = false, hasServiceError = false, appData, appData: { uiState = {}, customerInfo = {} }, loggedInCustomerData = {} } = {}, form: formData = {} } = state;
    // One off scenario for authenticated porting flow dob,
    // where customer found flag has to be taken from,
    // logged in customer data.
    const { customerFound = false } = loggedInCustomerData;
    const { customerAccountUUID = '', identityProfileId = '' } = customerInfo;
    const { loggedIn = false, isEsimActivation } = uiState;
    const { selectedCustomerType = '' } = uiState;
    const { serviceNumber: { simProfile: customerSimProfile } = {}, esimData } = appData || {};

    // validation
    // const isIdentityFormMedicare = selectedIdentityType === 'MEDICARE_CARD';
    const isBusinessCustomer = selectedCustomerType === 'business';

    // USER DATA
    // service number details
    let actualAmount = '';
    const { serviceNumber: { starterCredit } = {}, msisdn } = appData;
    const { updateCart: { data: { cartTotalPrice = [] } = {} } = {} } = appData;
    if (cartTotalPrice.length !== 0) {
        const { price: { dutyFreeAmount: { value = '' } = {} } = {} } = cartTotalPrice[0];
        actualAmount = value;
    }
    // selected offer
    const { offerDetailsForm: { values: { rechargeItem = {}, enableAutoRecharge = 'later', skipRecharge = false } = {} } = {} } = formData;
    let offerCode = rechargeItem.offerId;
    let rechargeAmount = rechargeItem.amount;
    if (isEsimActivation) {
        const { offer: { selectedOffer } = {} } = esimData;
        offerCode = selectedOffer;
    }

    const { paymentForm: { values: { paymentMethod = null } = {} } = {} } = formData;

    // getting selected offer details
    // const { locale: { offers = [] } = {} } = isEsimActivation ? state.welcome : state.offerDetails || {};
    // const offerSelected = offers.filter(offer => offer.id === offerCode)[0];
    // const dxpOffersDetails = state.app.appData.offers; // TODO
    // const dxpOffers = isEsimActivation ? state.welcome : dxpOffersDetails.offer || {}; // TODO
    // const offerSelected = dxpOffers.filter(offer => offer.id === offerCode)[0];  //remove comment
    const offerSelected = '';
    const { title: offerTitle = 'Prepaid plan title' } = offerSelected || {};
    if (isEsimActivation) {
        const { content: { rechargeContent: { amount } = {} } = {} } = offerSelected || {};
        rechargeAmount = amount;
    }
    const applicableBonus = getApplicableBonusOffer(customerSimProfile, offerSelected);
    // getting selected offer details
    let rechargeId = '';
    if (offerSelected != null) {
        if (skipRecharge) {
            rechargeId = null;
        } else {
            rechargeId = offerSelected.recharge && offerSelected.recharge.filter((rechargeItemData) => rechargeItemData.denomination === rechargeAmount)[0].rechargeId;
        }
    }
    const { shoppingCartId } = getShoppingCartId();
    // porting number details
    const { existingNumberForm: { values: { msisdn: portingNumber = '', currentServiceProvider = '', existingAccountNumber: currentAccountNumber = '' } = {} } = {} } = formData;

    // customer business details (if applicable)
    const { business: { values: { abn = null, acn, companyName = null, businessType = null } = {} } = {} } = formData;

    // customer contact details (if applicable)
    const { contactDetailsForm: { values: { contactEmail: contactEmailAddress } = {} } = {} } = formData || {};
    const { yourContactDetailsForm: { values: { contactEmail: yourContactEmailAddress } = {} } = {} } = formData || {};

    // customer personal details
    const { values: { lastName = null } = {} } = formData[selectedCustomerType] || {};
    const { values: { emailAddress: customerEmailAddress = null, abn: soleTraderAbn = null } = {} } = formData[selectedCustomerType] || {};
    const { values: { dobDay = null, dobMonth = null, dobYear = null } = {} } = formData[selectedCustomerType] || {};
    const { values: { telstraId = null, dlNumber = null, state: stateOfIssue = null, noDriversLicense = false } = {} } = formData[selectedCustomerType] || {};
    // customer identity details
    const {
        firstName: idFirstName,
        middleName: idMiddleName,
        lastName: idLastName,
        dobDay: idDobDay,
        dobMonth: idDobMonth,
        dobYear: idDobYear,
        licenseNumber,
        stateOfIssue: istateOfIssue,
        title = null
    } = getIdentityFormData();
    const { address: { addressData: { address = null } = {} } = {} } = appData;
    const { address: { verificationData = [], postCode = null } = {} } = appData;
    const { fullAddress: fullAddressDetails = null } = verificationData[0] || verificationData || {};
    // porting terms acceptance
    const { locale: { page: { pageMainContent: portingTerms = null } = {} } = {} } = state.portingTerms || {};

    // customer registration details (if applicable)
    const { registrationForm: { values: { userEmail: userEmailAddress } = {} } = {} } = formData || {};

    // final returns
    const customerStatus = isEmpty(telstraId) ? 'NEW' : 'EXISTING';
    let dobDayValue;
    let dobMonthValue;
    let dobYearValue;
    if (loggedIn && customerFound) {
        const { dob = '' } = loggedInCustomerData;
        const customerDob = moment(dob);
        dobDayValue = customerDob.date();
        dobMonthValue = parseInt(customerDob.month(), 10);
        dobYearValue = parseInt(customerDob.format('YYYY'), 10);
    } else {
        dobDayValue = idDobDay || dobDay;
        dobDayValue = dobDayValue !== null ? dobDayValue.replace(/^0+/, '') : '';
        dobMonthValue = idDobMonth || dobMonth;
        dobYearValue = idDobYear || dobYear;
    }
    const contactEmail = contactEmailAddress || customerEmailAddress || yourContactEmailAddress;
    const userEmail = userEmailAddress || customerEmailAddress;
    const fullDob = `${moment(dobDayValue, 'DD').format('D')} ${moment(parseInt(dobMonthValue, 10) + 1, 'MM').format('MMMM')} ${dobYearValue}`;
    const fullDobSlashed = `${moment(dobDayValue, 'DD').format('D')}/${moment(parseInt(dobMonthValue, 10) + 1, 'MM').format('MM')}/${dobYearValue}`;
    const abnValue = isBusinessCustomer ? abn : soleTraderAbn;

    let firstNameValue = loggedIn && loggedInCustomerData.firstName ? loggedInCustomerData.firstName : idFirstName;
    let lastNameValue = loggedIn && loggedInCustomerData.lastName ? loggedInCustomerData.lastName : lastName;
    firstNameValue = !idFirstName ? firstNameValue : idFirstName;
    lastNameValue = !idLastName ? lastNameValue : idLastName;
    const middleNameValue = idMiddleName;

    const customerType = selectedCustomerType.toUpperCase();

    let validDLValue = !noDriversLicense ? dlNumber : null;
    validDLValue = !isEmpty(validDLValue) && validDLValue !== null ? validDLValue : licenseNumber;

    // Medicare card has a combined field for full name
    const fullName = `${firstNameValue} ${lastNameValue}`;
    const validStateOfIssue = !isEmpty(stateOfIssue) ? stateOfIssue : istateOfIssue;

    /* eslint-disable camelcase */
    return {
        hasErrorOccurred,
        hasServiceError,
        identityProfileId,
        msisdn,
        firstName: firstNameValue,
        lastName: lastNameValue,
        fullName,
        middleName: middleNameValue,
        dobDay: dobDayValue,
        dobMonth: dobMonthValue,
        dobYear: dobYearValue,
        enableAutoRecharge,
        fullDob,
        fullDobSlashed,
        contactEmail,
        customerStatus,
        customerAccountUUID,
        abn: abnValue,
        telstraId,
        dlNumber: validDLValue,
        fullAddressDetails,
        starterCredit,
        address,
        offerTitle,
        offerCode,
        rechargeId,
        rechargeAmount,
        shoppingCartId,
        actualAmount,
        paymentMethod,
        companyName,
        acn,
        portingNumber,
        portingTerms,
        customerType,
        businessType: !isEmpty(businessType) ? businessType.toUpperCase() : null,
        title,
        postCode,
        stateOfIssue: validStateOfIssue,
        applicableBonus,
        currentServiceProvider,
        currentAccountNumber,
        userEmail
    };
};

const urlSearchParams = {};
const append = (name, value) => {
    urlSearchParams[name] = value;
};
const decode = (str) => {
    return decodeURIComponent(str.replace(/\+/g, ' '));
};

export function URLSearchParams(search = '') {
    let searchText = search;
    // searchText = searchText.toLowerCase() || '';
    if (searchText.indexOf('?') === 0) {
        searchText = searchText.slice(1);
    }
    const pairs = searchText.split('&');
    for (let j = 0; j < pairs.length; j += 1) {
        const value = pairs[j];
        const index = value.indexOf('=');
        // eslint-disable-next-line
        if (-1 < index) {
            append(decode(value.slice(0, index)), decode(value.slice(index + 1)));
        }
    }
    return urlSearchParams;
}

/**
 * Initialize Telstra live chat library.
 *
 * @export
 * @returns
 */
export function initLiveChat() {
    if (window.webMessaging) {
        window.webMessaging.init({
            section: ['telstra', 'prepaidactivation', 'troubleshoot', 'welcome'], // Section Array which need to be passed to LivePerson
            sourceSection: ['tcom'] // Source section is required to identify the source contatiner
        });
        pushUrlToLiveChat('welcome'); // Pushing context as welcome on inital load
    }
}

/**
 * Generate an RFC4122 version 4 compliant solution
 * for a UUID to be passed in to the service calls.
 *
 * @export
 * @returns
 */
export function generateUUID() {
    let dateTimeStamp = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        const random = (dateTimeStamp + Math.random() * 16) % 16 | 0; // eslint-disable-line no-mixed-operators,no-bitwise
        dateTimeStamp = Math.floor(dateTimeStamp / 16);
        return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16); // eslint-disable-line no-mixed-operators,no-bitwise
    });
    return uuid.toUpperCase();
}
/**
 * Identifies the id type of the number provided by the
 * customer.
 *
 * @param {any} number
 * @param {any} type
 * @returns
 */
export const getIdType = (number, type) => {
    let providedNumber = number;
    let idType;

    // ID type param is expected for IMSI and OTHER types. For MSISDN, it can be passed or auto detected
    if (type) {
        idType = type;
    } else if (providedNumber && providedNumber.indexOf('04') === 0 && providedNumber.length === 10) {
        idType = 'MSISDN';
    } else if (providedNumber && (providedNumber.length === 9 || providedNumber.length === 10 || providedNumber.length === 13 || providedNumber.length === 14)) {
        idType = 'SIM_SERIAL_NUMBER';
        if (providedNumber.length === 10) {
            providedNumber = providedNumber.substring(0, 9);
        } else if (providedNumber.length === 14) {
            providedNumber = providedNumber.substring(0, 13);
        }
    } else if (providedNumber && !Number.isNaN(providedNumber)) {
        idType = 'IMSI'; // populated
    } else {
        idType = 'OTHER'; // prepopulated
    }
    return idType;
};

export const getSerialNumber = (simSerial) => {
    let sanitizedSimSerial = simSerial;
    // Logic to convert 20 digit long sim serial into 9 or 13 digits
    if (simSerial && simSerial.length > 13) {
        const serial = simSerial.substring(14, 18);
        let isThirteen = false;

        for (let i = 0; i < serial.length; i += 1) {
            if (parseInt(serial[i], 10) > 0) {
                isThirteen = true;
                break;
            }
        }

        const subSerial = isThirteen ? simSerial.substring(6, 18) : simSerial.substring(6, 14);
        sanitizedSimSerial = subSerial + simSerial.substring(19);
    }
    return sanitizedSimSerial;
};

export const isPaypalServiceAvailable = () => !isEmpty(window.paypal) && isPaypalPaymentAvailable();
export const isVisaCheckoutServiceAvailable = () => !isEmpty(window.V) && isVisaChekoutPaymentAvailable();
export const getApplicableBonusOffer = (customerSimProfile = '', selectedOffer = {}) => {
    const { bonusData = [] } = selectedOffer;
    const validBonusOffers = bonusData.filter((bonus) => {
        if (bonus.enabled && bonus.simProfile.indexOf(customerSimProfile) !== -1) {
            if (!isEmpty(bonus.startDate) && !isEmpty(bonus.endDate)) {
                // today's date should be between start and end date.
                if (moment().isBetween(moment(bonus.startDate, 'DD/MM/YYYY'), moment(bonus.endDate, 'DD/MM/YYYY'))) {
                    return bonus;
                }
            } else {
                // if no start and end date in bonus then
                // it is always available bonus.
                return bonus;
            }
        }
        return false;
    });

    // Offer with start and end date takes precedence.
    const applicableBonus = validBonusOffers.filter((offer) => !isEmpty(offer.startDate))[0] || validBonusOffers[0];
    return applicableBonus;
};

export const isSelectedDeviceMobile = (device) => device === 'Prepaid-Mobile-3G-Handset';
export const isSelectedDeviceMBB = (device) => device === 'Telstra-Prepaid-Wireless-Broadband';

export const storeProcessToken = (token) => {
    // Pushing process token in local storage for new relic to pick up the value
    // New relic will intercept this and not add the token to local storage
    // See also app/actions.js
    if (window.localStorage) {
        window.localStorage.setItem('processToken', token);
    }
};

export const isPaymentAvailable = () => {
    return PPA_CREDIT_CARD_ENABLED || PPA_PAYPAL_ENABLED || PPA_VISA_ENABLED || false;
};

export const isCreditCardPaymentAvailable = () => {
    return PPA_CREDIT_CARD_ENABLED || false;
};

export const isPaypalPaymentAvailable = () => {
    return PPA_PAYPAL_ENABLED || false;
};

export const isVisaChekoutPaymentAvailable = () => {
    return PPA_VISA_ENABLED || false;
};

export const processMsidn = (msisdn = '') => {
    let sanitisedMsisdn = msisdn;
    if (msisdn.substring(0, 2) === '61') {
        sanitisedMsisdn = `0${sanitisedMsisdn.slice(2)}`;
    }
    return sanitisedMsisdn;
};

export const getFirstApplicableOffer = (customerOffers = [], esimOffers = []) => {
    return esimOffers.find((offer) => customerOffers.indexOf(offer.id) !== -1) || {};
};

export const getEsimConfigurations = () => {
    const { esimConfigurations = {} } = window.appConfig;
    return esimConfigurations;
};

/**
 * Checks connectivity as a workaround fix for zero rated
 * sites.
 *
 * @export
 * @returns
 */
export function checkNetworkConnection() {
    return axios
        .get('https://bam.nr-data.net/1/9930794d94?a=86549383')
        .then(() => {
            store.dispatch({ type: NETWORK_CONNECTION_STATUS, status: true });
        })
        .catch(() => {
            store.dispatch({ type: NETWORK_CONNECTION_STATUS, status: false });
        });
}

/**
 * historyLocationListener - history location change handler
 *
 * @param {object} location - history update location object
 * @param {string} action - history update action
 */
export const historyLocationListener = (location, action) => {
    const { pathname = '' } = location;
    const { app: { hasErrorOccurred = false } = {} } = store.getState();
    if (pathname.length && action === 'POP' && (pathname === '/review-details' || pathname === '/payment' || hasErrorOccurred)) {
        window.location.reload();
    }
    trackHistoryLocationChange(location, action);
};

/**
 * getRoutesFromPathName - get matching app flow routes from given location pathName
 *
 * @param {object} pathName - pathName to find in app flow routes
 * @return {array}
 */
export const getRoutesFromPathName = (pathName = '') => {
    if (!pathName.length) {
        return [];
    }

    const locationParts = pathName.split('/').reverse();
    if (locationParts.length === 0) {
        return [];
    }

    return applicationFlow.filter((appFlowRoute) => {
        return appFlowRoute.path.indexOf(`/${locationParts[0]}`) !== -1;
    });
};
export const getCurrentRouteName = () => {
    const state = store.getState();
    const { pathname: currentLocation } = state.router.location;
    // const currentRouteName = !isEmpty(REACT_BASE_PATH) ? currentLocation.split(REACT_BASE_PATH).pop() : currentLocation;
    const currentRouteName = currentLocation;
    let routename = '';
    applicationFlow.forEach((value, index) => {
        if (applicationFlow[index].path === currentRouteName) {
            routename = applicationFlow[index];
        }
    });
    return routename;
};

/**
 * concatNames - concating names for ID details
 *
 * @param {string} -  fname, middlename, lname
 */
export const concatNames = (fname, middlename = '', lname = '') => {
    let fullName = !isEmpty(middlename) ? `${fname} ${middlename} ${lname}` : `${fname} ${lname}`;
    fullName = fullName.trim();
    return fullName;
};

export const checkTypeInuserProfileInfoMeta = (type) => {
    const values = Object.values(userProfileInfoMeta);
    return values.indexOf(type) > -1;
};

export const submitFailure = (fieldList) => {
    let OSName = 'Not known';
    return (errors = {}) => {
        // Field Error(s). We need to step through fieldList to guarantee ordering (since redux-form returns an errors object, not array or map)
        fieldList.find((field) => {
            if (errors[field]) {
                const elem = document.querySelector(`input[id=${field}], select[name=${field}], input[id=autosuggest-${field}], legend[id=${field}Legend]`);
                if (elem) {
                    if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
                    if (OSName !== 'MacOS') {
                        elem.setAttribute('aria-invalid', 'true');
                        elem.setAttribute('aria-describedBy', `${elem.getAttribute('aria-describedBy')} ${field}Error`);
                    }
                    elem.focus();
                    const errorSpan = document.getElementById(`${field}Error`);
                    if (errorSpan) {
                        errorSpan.innerText = Parser(errors[field]);
                    }
                    return true;
                }
            }
            // continue stepping through fieldList
            return false;
        });
    };
};

export const formatMobileNumber = (msisdn) => {
    if (msisdn) {
        const regex = /^\+61|^61/;
        const numberToBeFormatted = msisdn.replace(regex, 0);
        if (numberToBeFormatted.length === 10) {
            return `${numberToBeFormatted.substring(0, 4)} ${numberToBeFormatted.substring(4, 7)} ${numberToBeFormatted.substring(7, 10)}`;
        }
    }
    return msisdn;
};

export const showChatButton = (val) => {
    if (window.webMessaging && window.webMessaging.setPersistentParameters) {
        window.webMessaging.setPersistentParameters(['telstra', 'dtq', 'prepaid-ppv'], val);
    }
};

export const pushUrlToLiveChat = (pageName) => {
    if (!pageName) {
        return;
    }
    if (window.lpTag && window.lpTag.newPage) {
        window.lpTag.newPage(document.URL, { section: ['telstra', 'prepaidactivation', 'troubleshoot', pageName] });
    }
};

export const pushErrorContextToLiveChat = (contextId, errorMessage) => {
    if (window.lpTag && window.lpTag.sdes) {
        window.lpTag.sdes.push({
            type: 'error', // MANDATORY
            error: {
                contextId,
                message: errorMessage // ERROR MESSAGE
            }
        });
    }
    showChatButton(false);
};

export const showWebMessenger = (errorMessage, errorType = 'error') => {
    addDataLayerEventInfo('liveChatStarted', 'liveChat');
    const { brandName } = getBrandDetails();
    let brandTag = 'telstra';
    if (brandName === BRANDS.JBHiFi.name) {
        brandTag = 'jb-hi-fi-service';
    }
    if (window.webMessaging && window.webMessaging.show) {
        window.webMessaging.show({
            isDirect: true,
            section: [brandTag, 'dtq', 'prepaid-activation', errorType]
        });
        pushErrorContextToLiveChat(`${brandName} Pre-Paid Activation Error`, errorMessage);
        addDataLayerEventInfo('liveChatStarted', 'liveChat', '', errorMessage);
    }
};

export const getdayMonthYearFromDate = (date, separator = '/') => {
    if (!date) {
        return [];
    }
    return date.split(separator);
};

export const getFormValues = () => {
    const data = store.getState();
    const { form = {}, app: { appData: { address = {} } = {} } = {}, yourContactDetails = {} } = data;
    const { identityDetailsForm = {}, simValidationForm = {}, yourContactDetailsForm = {}, businessRegistrationForm = {} } = form;
    const { verificationData = {} } = address;
    const {
        ABN,
        acn,
        businessType,
        emailAddress,
        licenseNumber,
        title,
        stateOfIssue,
        soleTraderCheck,
        passportNumber,
        visaNumber,
        countryOfIssue,
        cardColour,
        cardNumber,
        expiry,
        individualReferenceNumber,
        businessName,
        businessEmail,
        businessPhoneNumber,
        businessAddress: { value: selectedBusinessAddressText } = {},
        businessContactSameAsPersonalContact
    } = {
        ...identityDetailsForm.values,
        ...simValidationForm.values,
        ...yourContactDetailsForm.values,
        ...businessRegistrationForm.values
    };
    const addressId = verificationData.addressId || null;
    const state = verificationData.state || null;
    const locality = verificationData.localityName || null;
    const postcode = verificationData.postcode || null;
    const payload = {
        acn,
        businessType,
        contactEmail: emailAddress,
        customerType: 'PERSONAL',
        fullAddressDetails: {
            addressStatus: 'CONFIRMED',
            addressIdentification: [
                {
                    type: 'ID',
                    identifier: addressId,
                    issuer: 'ADBOR'
                }
            ],
            exchange: null,
            localityDetails: {
                state,
                locality,
                postcode
            },
            propertyAddressDetails: null,
            // "propertyAddressDetails": {
            //     "streetDetails": {
            //         "streetName": "EXHIBITION",
            //         "streetType": "ST"
            //     },
            //     "propertyNumberFrom": 242
            // },
            latitude: null,
            longitude: null
        },
        licenseNumber,
        postCode: postcode,
        title,
        stateOfIssue,
        customerStatus: 'NEW',
        passportNumber,
        visaNumber,
        countryOfIssue,
        cardColour,
        cardNumber,
        expiry,
        individualReferenceNumber,
        selectedBusiness: yourContactDetails.selectedBusiness.selectedBusiness
    };

    if (soleTraderCheck) {
        payload.customerType = 'SOLETRADER';
        payload.abn = ABN;
        payload.companyName = businessName;
        payload.businessPhone = businessPhoneNumber;
        if (businessContactSameAsPersonalContact) {
            payload.businessAddress = addressId;
            payload.businessEmail = emailAddress;
        } else {
            payload.businessAddress = selectedBusinessAddressText;
            payload.businessEmail = businessEmail;
        }
    }
    return payload;
};

/**
 * generates a random number
 *
 * @param {number} [min=10000] - the minimum number for generatation
 * @param {number} [max=99999] - the maxiumum number for generatation
 *
 * @returns {number} random number between min and max
 */
export const generateId = (min = 10000, max = 99999) => {
    if (typeof min !== 'number' && typeof max !== 'number') {
        return false;
    }
    const random_number = Math.random() * (max - min) + min;
    return Math.floor(random_number);
};

export const scrollToBody = (value = 0) => {
    const element = document.querySelector('body');
    element.scrollTop = value;
    element.scrollLeft = 0;
};

/**
 * get expiry from the passed usage quota array
 *
 * @param {array} - usage quota
 *
 * @returns {string}
 */
export const getExpiry = (usageQuota) => {
    let expiry = '';
    for (let i = 0; i < usageQuota.length; i += 1) {
        if (usageQuota[i].includes('expiry')) {
            expiry = usageQuota[i].split(' expiry');
            return expiry[0];
        }
    }

    return expiry;
};

/**
 * return true for 33% of chance
 *
 *
 * @returns {boolean}
 */
export const randomChanceGenerator = () => {
    return Math.random() < 0.2;
};

export const isOutageWindow = (startTime, endTime) => {
    let outageDisplay = false;
    const currentDate = moment().tz('Australia/Brisbane').format('YYYY-MM-DDTHH:mm');
    if (moment(currentDate).isSameOrAfter(moment(startTime)) && moment(currentDate).isSameOrBefore(moment(endTime))) {
        outageDisplay = true;
    }
    return outageDisplay;
};

export const getBrandDetails = () => {
    const { welcome: { deviceData: { brand = BRANDS.Telstra.name } = {} } = {} } = store.getState();
    let brandName;
    let displayName;
    if (brand === 'JBHiFi') {
        brandName = BRANDS.JBHiFi.name;
        displayName = BRANDS.JBHiFi.displayName;
    } else {
        brandName = brand;
        displayName = brand;
    }
    return { brandName, displayName };
};

export const applyValuesToTemplate = (template = '', data = {}) => {
    return template.replace(/{{\w*}}/g, (match) => {
        const key = match.substring(2, match.length - 2);
        return data[key] || '';
    });
};

/**
 * apply brand details to a string template
 *
 * @param {string} template
 * @example
 * - This is a {{brandName}} SIM
 *
 * @returns {string} Brand details applied string
 * @example
 * - This is a Telstra SIM
 */
export const applyBrandDataInTemplate = (template) => applyValuesToTemplate(template, getBrandDetails());

export const getBrandNameFromNonTPostStatus = (status) => {
    if (typeof status === 'string') {
        const brand = (status.match(/^ERROR-NONTPOST-([A-Za-z\s].*)$/) || [])[1];
        if (brand) return brand.trim();
    }
    return '';
};

export function getPlanTypeFromPlanId(planId = '') {
    if (planId.endsWith('MB')) {
        // Pre-Paid Mobile Broadband plan
        return 'TPPMB';
    } if (planId.endsWith('GB')) {
        // Pre-Paid Casual plan
        return 'TPPGB';
    } if (planId.endsWith('CP')) {
        // Pre-Paid Mobile plan
        return 'TPPCP';
    }
    return undefined;
}

export const getAbleSpriteSheet = () => ableSpriteSheet;

export const isIdvModuleThirdParty = () => window.appConfig?.PPA_USE_NATIVE_IDV_MODULE !== 'true';